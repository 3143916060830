<template>
  <v-container>
    <v-form @submit.prevent="InsertUpdate()">
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-text-field
            label="ชื่อสวน"
            placeholder="ชื่อสวน"
            :rules="[rules.required(RubberStatus)]"
            outlined
            dense
            v-model="Rubber.RubberName"
            @blur="Blur(Rubber.RubberName)"
            required
            ref="RubberName"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-textarea
            label="ที่อยู่"
            placeholder="ที่อยู่"
            outlined
            dense
            v-model="Rubber.RubberAddress"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="12" lg="6">
          <v-textarea
            label="หมายเหตุ"
            placeholder="หมายเหตุ"
            outlined
            dense
            v-model="Rubber.RubberRemark"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" md="6" lg="1">
          <v-btn block type="submit">บันทึก</v-btn>
        </v-col>
        <v-col cols="12" sm="12" md="6" lg="1">
          <v-btn block @click="$router.push('/rubber')">กลับ</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>
<script>
import Axios from "axios";

export default {
  data() {
    return {
      Rubber: {
        RubberId: "",
        RubberName: "",
        RubberAddress: "",
        RubberRemark: ""
      },
      RubberStatus: true,
      RubberName: "",
      rules: {
        required: value => !!value || "ชื่อสวนซ้ำ"
      },
    };
  },
  mounted() {
    this.$nextTick(() => {
      console.log(this.$route.query.RubberId != null)
      if (this.$route.query.RubberId != null) {
        this.Rubber.RubberId = this.$route.query.RubberId;
        this.SelectId(this.Rubber.RubberId);
      }
    });
  },
  methods: {
    Blur(v) {
      if (!v) return;
      Axios.post("/api/v1/select/selectrubbername/rubber", { RubberName: v }).then(res => {
        if (res.status == 200) {
          if (res.data.UserStatus) {
            if (this.RubberName != v && this.$route.query.RubberId != null) {
              this.RubberStatus = false;
              this.Rubber.RubberName = "";
              this.$refs.RubberName.focus();
            } else if (this.$route.query.RubberId == null) {
              this.RubberStatus = false;
              this.Rubber.RubberName = "";
              this.$refs.RubberName.focus();
            } else {
              this.RubberStatus = true;
            }
          } else {
            this.RubberStatus = true;
          }
        }
      });
    },
    SelectId(v) {
      Axios.get("/api/v1/select/SelectId/rubber?RubberId=" + v).then(res => {
        if (res.status == 200) {
          this.Rubber = res.data;
          this.RubberName = res.data.RubberName;
        }
      });
    },
    InsertUpdate() {
      if (this.$route.query.RubberId != null) {
        Axios.put("/api/v1/update/update/rubber", this.Rubber).then(res => {
          if (res.status == 200) {
            this.$router.push("/rubber");
          }
        });
      } else {
        this.Rubber.RubberId = 0;
        Axios.post("/api/v1/insert/insert/rubber", this.Rubber).then(res => {
          if (res.status == 200) {
            this.$router.push("/rubber");
          }
        });
      }
    }
  }
};
</script>
